import React from "react"

import { rem } from "polished"

const theme = {
  colors: {
    hyperlink: "#0058A3",
    indigo: "#4e53d1",
    tangerine: "#F9A900",
    tangerineDark: "#D59000",
    sienna: "#F26B4E",
    siennaDark: "#D63310",
    blossom: "#D9B2BB",
    blossomDark: "#BA6E80",
    sky: "#78ABE8",
    skyDark: "#215FAA",
    rhino: "#283460",
    sherpa: "#053B41",
    jade: "#01585D",
    jadeDark: "#083234",
    aquamarine: "#68D3A5",
    acapulco: "#77AC95",
    acapulcoDark: "#3D816C",
    alabaster: "#F8F7F4",
    snow: "#E8E8E3",
    sand: "#B5AA8A",
    grey50: "#F7F7F9",
    grey100: "#EEEEF0",
    grey200: "#E2E2E4",
    grey300: "#D0D0D2",
    grey400: "#ABABAD",
    grey500: "#8A8A8C",
    grey600: "#636365",
    grey700: "#505052",
    grey800: "#323234",
    grey900: "#121214",
    error: "crimson",
  },
  sizes: {
    /** 4px */
    "3xs": rem("4px"),
    /** 8px */
    "2xs": rem("8px"),
    /** 12px */
    xs: rem("12px"),
    /** 14px */
    sm: rem("14px"),
    /** 16px */
    md: rem("16px"),
    /** 24px */
    lg: rem("24px"),
    /** 32px */
    xl: rem("32px"),
    /** 40px */
    "2xl": rem("40px"),
    /** 48px */
    "3xl": rem("48px"),
    /** 64px */
    "4xl": rem("64px"),
    /** 96px */
    "5xl": rem("96px"),
    /** 640px */
    smallBlock: rem("640px"),
  },
  space: {
    /** 4px */
    "3xs": rem("4px"),
    /** 8px */
    "2xs": rem("8px"),
    /** 12px */
    xs: rem("12px"),
    /** 14px */
    sm: rem("14px"),
    /** 16px */
    md: rem("16px"),
    /** 24px */
    lg: rem("24px"),
    /** 32px */
    xl: rem("32px"),
    /** 40px */
    "2xl": rem("40px"),
    /** 48px */
    "3xl": rem("48px"),
    /** 64px */
    "4xl": rem("64px"),
    /** 96px */
    "5xl": rem("96px"),
  },
  fonts: {
    primary:
      'sofia-pro, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;',
    secondary:
      'miller-display, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;',
  },
  fontSizes: {
    /** 10px */
    "2xs": rem("10px"),
    /** 12px */
    xs: rem("12px"),
    /** 14px */
    sm: rem("14px"),
    /** 16px */
    md: rem("16px"),
    /** 18px */
    lg: rem("18px"),
    /** 20px */
    xl: rem("20px"),
    /** 24px */
    "2xl": rem("24px"),
    /** 28px */
    "3xl": rem("28px"),
    /** 34px */
    "4xl": rem("34px"),
    /** 42px */
    "5xl": rem("42px"),
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
  },
  lineHeights: {
    normal: "normal",
    none: "1",
    shorter: "1.25",
    short: "1.375",
    base: "1.5",
    tall: "1.625",
  },
  fontWeights: {
    normal: 400,
    semi: 600,
    bold: 700,
  },
  shadows: {
    md: "0 1px 3px rgba(0,0,0,0.15), 0 8px 8px rgba(0,0,0,0.04)",
  },
  icons: {
    checkmark: {
      path: (
        <polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039"></polygon>
      ),
      viewBox: "0 0 14 14",
    },
    chevronRight: {
      path: (
        <path
          fill="currentColor"
          d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
        />
      ),
      viewBox: "0 0 24 24",
    },
    chevronLeft: {
      path: (
        <path
          fill="currentColor"
          d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"
        />
      ),
      viewBox: "0 0 24 24",
    },
    info: {
      path: (
        <path
          fill="currentColor"
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
        />
      ),
      viewBox: "0 0 24 24",
    },
    print: {
      path: (
        <path
          fill="currentColor"
          d="M16 18h-8v-1h8v1zm-2 1h-6v1h6v-1zm10-14v13h-4v6h-16v-6h-4v-13h4v-5h16v5h4zm-18 0h12v-3h-12v3zm12 10h-12v7h12v-7zm4-8h-20v9h2v-3h16v3h2v-9zm-1.5 1c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5z"
        />
      ),
      viewBox: "0 0 24 24",
    },
    modalClose: {
      path: (
        <g
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="3"
        >
          <path d="M2 2l40 40" />
          <path d="M42 2l-40 40" />
        </g>
      ),
      viewBox: "0 0 44 44",
    },
    email: {
      path: (
        <g fill="currentColor">
          <path d="M.853 30.25zM32.25 9.276c-1.245-.722-2.475-1.47-3.725-2.185a.611.611 0 01-.35-.633c.02-.891.007-1.783.007-2.674 0-.746-.133-.877-.892-.877-1.835 0-3.67 0-5.5-.007a1.218 1.218 0 01-.58-.157q-2.2-1.282-4.385-2.594a.8.8 0 00-.922-.006c-1.41.85-2.833 1.678-4.245 2.526a1.6 1.6 0 01-.874.243 543.519 543.519 0 00-5.566 0c-.479 0-.678.2-.679.683 0 1.005-.008 2.011 0 3.016a.411.411 0 01-.238.413c-1.27.741-2.529 1.5-3.8 2.235a.893.893 0 00-.5.876q.014 9.64.007 19.281c0 .678.161.837.847.837h31.067c.6 0 .791-.181.791-.769q0-9.7.005-19.4a.832.832 0 00-.468-.808zm-4.052-1.031l2.78 1.642-2.78 1.643zM14.689 2.223c.5-.3 1-.6 1.5-.882a.335.335 0 01.273-.034c.868.5 1.728 1.013 2.576 1.573h-5.343l-.032-.046zm-8.986 2.1c0-.071.011-.142.019-.239h21.292v.321c0 2.508 0 5.016.005 7.524a.463.463 0 01-.265.462q-5.07 2.989-10.129 6a.441.441 0 01-.526.007q-5.058-3.009-10.126-6c-.156-.092-.278-.167-.277-.392.01-2.563.007-5.123.008-7.683zm-1.184 3.92v3.29L1.738 9.887zm27 20.829H1.195V10.935c.12.068.225.125.327.185q7.125 4.215 14.246 8.439a1 1 0 001.167.007q7.128-4.242 14.273-8.454c.095-.056.193-.107.316-.175z" />
          <path d="M23.224 9.901H9.542a.68.68 0 00-.362.043c-.156.113-.35.266-.384.431a.7.7 0 00.187.542.777.777 0 00.512.148h13.707a.6.6 0 00.673-.573.606.606 0 00-.651-.591zM11.196 11.071h5.107-5.107zM9.302 7.714c.1.009.207 0 .311 0H23.2a.572.572 0 00.643-.563.581.581 0 00-.614-.6H9.576a1.873 1.873 0 00-.34.018.56.56 0 00-.473.6.566.566 0 00.539.545z" />
          <path d="M16.299 7.718h6.9q-3.448.003-6.9 0z" />
        </g>
      ),
      viewBox: "0 0 32.717 30.25",
    },
  },
}

export type Theme = typeof theme

export default theme
