import React from "react"
import styled from "@emotion/styled"
import { Link, GatsbyLinkProps } from "gatsby"
import Text from "@ui/Text/Text"

const StyledLogo = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #fff;
  font-size: ${(props) => props.theme.fontSizes.lg};
  font-weight: 700;

  :focus {
    outline: 0;
  }
`

const Logo = (
  props: Partial<Omit<GatsbyLinkProps<never>, "children" | "ref">>
) => {
  return (
    <StyledLogo to="/" tabIndex={-1} {...props}>
      <Text as="span" fontSize="inherit" color="aquamarine">
        Guide for Integrated Planning
      </Text>
      <span>in Africa</span>
    </StyledLogo>
  )
}

export default Logo
