import { css, Global } from "@emotion/react"
import normalizeStyles from "emotion-normalize"
import React from "react"
import theme from "./theme"

const GlobalStyles = () => (
  <Global
    styles={css`
      ${normalizeStyles}

      html {
        box-sizing: border-box;
        font-family: ${theme.fonts.primary};
        color: ${theme.colors.grey800};

        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;

        line-height: 1.5;

        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
      }

      body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      *,
      *::before,
      *::after {
        box-sizing: inherit;
      }

      * {
        box-sizing: border-box;
      }
    `}
  />
)

export default GlobalStyles
