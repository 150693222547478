import { centerLayoutStyle } from "@components/centerlayout"
import styled from "@emotion/styled"
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock"
import { rem } from "polished"
import React, { useEffect, useRef, useState } from "react"
import Hamburger from "./hamburger"
import Logo from "./Logo"
import Nav from "./nav"

const Box = styled.header`
  position: relative;
  /* Increase z-index so shadows don't get clipped */
  z-index: 1;
  background: ${(props) => props.theme.colors.rhino};
  padding: ${(props) => props.theme.space.xl};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05), 0 2px 7px rgba(0, 0, 0, 0.12);

  &[data-expanded="true"] {
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
`

// prettier-ignore
const Layout = styled.div`
  ${centerLayoutStyle};
  position: relative;
  height: 100%;
  line-height: 1.25;
  max-width: ${rem("960px")};

  display: flex;
  justify-content: space-between;

  ${Box}[data-expanded="true"] & {
    flex-direction: column;
  }
`

const FixedHamburgesa = styled(Hamburger)`
  position: absolute;
  z-index: 1010;
  top: 0;
  right: 0;
  display: none;

  @media screen and (max-width: 48em) {
    display: block;
  }
`

const HorizontalNav = styled(Nav)`
  grid-template-columns: repeat(4, auto);
  grid-gap: ${(props) => props.theme.space["2xl"]};

  @media screen and (max-width: 48em) {
    display: none;
  }
`

const VerticalNav = styled(Nav)`
  flex-grow: 1;
  display: grid;
  grid-template-rows: repeat(4, min-content);
  grid-gap: ${(props) => props.theme.space["4xl"]};
  justify-content: center;
  align-content: center;
`

const Header = () => {
  const [isMobile, expandMobile] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (ref.current) {
      disableBodyScroll(ref.current)
    }
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  return (
    <Box data-expanded={isMobile}>
      <Layout>
        <Logo />
        {isMobile ? <VerticalNav /> : <HorizontalNav />}
        <FixedHamburgesa
          active={isMobile}
          onClick={() => expandMobile((prev) => !prev)}
        />
      </Layout>
    </Box>
  )
}

export default Header
