import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const StyledNav = styled.nav`
  display: grid;
`

const NavLink = styled(Link)`
  text-decoration: none;
  color: #fff;

  display: block;

  font-weight: 600;
  transition: all 0.15s ease-in-out;

  :hover,
  :focus {
    outline: 0;
    opacity: 0.65;
  }
`

const Nav = (
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
) => {
  return (
    <StyledNav {...props}>
      <NavLink to="/background">Background</NavLink>
      <NavLink to="/how-to">
        How to use
        <br /> this Guide
      </NavLink>
      <NavLink to="/relevant-tools">
        Relevant
        <br /> Tools
      </NavLink>
      <NavLink to="/contact">
        Contact
        <br /> &amp; support
      </NavLink>
    </StyledNav>
  )
}

export default Nav
