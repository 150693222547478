/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { ThemeProvider } from "@emotion/react"
import PropTypes from "prop-types"
import React, { ReactNode } from "react"
import styled from "@emotion/styled"
import Box from "@ui/Box"
import theme from "@ui/theme"
import {
  Link as GatsbyLink,
  GatsbyLinkProps,
  useStaticQuery,
  graphql,
} from "gatsby"
import GlobalStyles from "@ui/GlobalStyles"

import Header from "./header"
import CenterLayout from "./centerlayout"
import Text, { TextProps } from "@ui/Text/Text"
import undpLogo from "../images/undp_logo.svg"
import afdbLogo from "../images/afdb_logo.svg"
import nepadLogo from "../images/nepad_logo.svg"
import unecaLogo from "../images/uneca_logo.svg"

interface Props {
  children: ReactNode
}

const Main = styled(Box.withComponent("main"))`
  position: relative;
  display: flex;
  flex-direction: column;
`

const Footer = Box.withComponent("footer")

const FooterLabel = (props: TextProps) => <Text as="h3" {...props} />

FooterLabel.defaultProps = {
  color: "sherpa",
  fontSize: "sm",
  fontWeight: "bold",
  mb: "md",
}

const NavLink = styled((props: TextProps & GatsbyLinkProps<any>) => (
  <Text as={GatsbyLink} {...(props as any)} />
))`
  display: block;
`

NavLink.defaultProps = {
  color: "sherpa",
  fontSize: "sm",
  py: "xs",
}

const FooterLayout = styled(CenterLayout)`
  display: grid;

  grid-template-rows: auto auto auto;
  grid-template-areas:
    "."
    "."
    "producedBy";

  @media screen and (min-width: 37rem) {
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    grid-template-areas:
      ". producedBy"
      ". producedBy";
  }

  @media screen and (min-width: 55rem) {
    grid-template-rows: 1fr;
    grid-template-columns: minmax(auto, max-content) 1fr min-content;
    grid-template-areas: ". . producedBy";
  }

  grid-gap: ${(props) => props.theme.space.xl};
`

const ProducedBy = styled.div`
  grid-area: producedBy;
`

const Logos = styled.div`
  display: flex;
  flex-direction: column;
`

const ImgLink = styled.a`
  text-decoration: none;
  display: block;
  margin-bottom: 1rem;
  transition: 0.2s ease-in-out;

  :hover,
  :focus {
    outline: 0;
    opacity: 0.65;
  }
`

const query = graphql`
  query {
    allMdx(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: { frontmatter: { kind: { eq: "module" } } }
    ) {
      edges {
        node {
          body
          frontmatter {
            kind
            id
            name
            title
            order
          }
        }
      }
    }
  }
`

interface Data {
  allMdx: {
    edges: {
      node: {
        frontmatter: {
          name: string
          id: string
        }
      }
    }[]
  }
}

const Layout = ({ children }: Props) => {
  const data: Data = useStaticQuery(query)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header />
      <Main>{children}</Main>
      <Footer mt="auto" py="4xl" px="lg" backgroundColor="snow">
        <FooterLayout>
          <div>
            <FooterLabel>Site Overview</FooterLabel>
            <NavLink to="/background">Background</NavLink>
            <NavLink to="/how-to">How to use this Guide</NavLink>
            <NavLink to="/relevant-tools">Relevant Tools</NavLink>
            <NavLink to="/contact">Contact &amp; support</NavLink>
          </div>
          <div>
            <FooterLabel>Modules</FooterLabel>
            {data.allMdx.edges.map(
              ({
                node: {
                  frontmatter: { id, name },
                },
              }) => (
                <NavLink key={id} to={`/modules/${id}`}>
                  {name}
                </NavLink>
              )
            )}
          </div>
          <ProducedBy>
            <FooterLabel>Produced by</FooterLabel>
            <Logos>
              <ImgLink
                href="https://www.africa.undp.org"
                rel="noopener"
                target="_blank"
              >
                <img src={undpLogo} height="82" alt="UNDP" />
              </ImgLink>
              <ImgLink
                href="https://www.afdb.org/en"
                rel="noopener"
                target="_blank"
              >
                <img src={afdbLogo} height="68" alt="AFDB" />
              </ImgLink>
              <ImgLink
                href="https://www.uneca.org"
                rel="noopener"
                target="_blank"
              >
                <img src={unecaLogo} height="40" alt="UNECA" />
              </ImgLink>
              <ImgLink
                href="https://www.nepad.org"
                rel="noopener"
                target="_blank"
              >
                <img src={nepadLogo} height="52" alt="NAPTA" />
              </ImgLink>
            </Logos>
          </ProducedBy>
        </FooterLayout>
      </Footer>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
